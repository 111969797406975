.ShowStory {


  .ShowStoryInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 2rem;
    gap: 1rem;


    .ShowStoryInfo__title {
      font-size: 2.5rem;
      font-weight: normal;
      margin: 0;
      text-align: left;
      padding: 0;
    }

    .ShowStoryInfo__date {
      font-size: 0.8rem;
      font-weight: normal;
      text-align: left;
      padding: 0;
      color: #FFF;
    }

    .ShowStoryInfo__title__icon {
      font-size: 3rem;
      font-weight: normal;
      font-family: 'icon';
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 3px solid #FFF;
      color: #FFF;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

  }


  .rotate_plus { transform: rotate(15deg);}

  .rotate_minus {transform: rotate(-15deg);}

  .ShowStory__images {
      display: flex;
      justify-content: center;
    flex-wrap: wrap;
  }

  .ShowStory__images__item {
    width: 400px;
    //height: 500px;
    border-radius: 15px;
    overflow: hidden;
    margin: 2rem;
    border: 4px solid #FFF;
    display: inline-block;
    background-color: #FFF;

    h5 {
      font-size: 1.2rem;
      font-weight: normal;
      text-align: center;
    }

    p {
      font-size: 1rem;
      font-weight: normal;
      color: rgba(0,0,0,0.5);
      text-align: center;
    }

    img {
      width: 100%;
      //height: 100%;
      object-fit: cover;
    }
  }

  // XS - Phone
  @media (max-width: 480px) {

  }

  // S - Tablet
  @media (max-width: 768px) {

  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {

  }

  // L - High Res
  @media (max-width: 1200px) {

  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {

  }

}