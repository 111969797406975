/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
a {
  text-decoration: none;
}

/** FONTS **/
/*! main-text-regular */
@font-face {
  font-family: "main-text-regular";
  src: url("../fonts/freesans.woff") format("woff"), url("../fonts/freesans.woff2") format("woff2");
}
/*! main-text-bold */
@font-face {
  font-family: "main-text-bold";
  src: url("../fonts/freesansbold.woff") format("woff"), url("../fonts/freesansbold.woff2") format("woff2");
}
/*! main-text-link */
@font-face {
  font-family: "main-text-link";
  src: url("../fonts/freesansbold.woff") format("woff"), url("../fonts/freesansbold.woff2") format("woff2");
}
/*! label-text */
@font-face {
  font-family: "label-text";
  src: url("../fonts/freesans.woff") format("woff"), url("../fonts/freesans.woff2") format("woff2");
}
/*! icon */
@font-face {
  font-family: "icon";
  src: url("../fonts/ebbicons-regular.woff") format("woff"), url("../fonts/ebbicons-regular.woff2") format("woff2");
}
/*! heading */
@font-face {
  font-family: "heading";
  src: url("../fonts/freesansbold.woff") format("woff"), url("../fonts/freesansbold.woff2") format("woff2");
}
/** CUSTOM PROPERTIES **/
:root {
  /** COLOR **/
  /*! Background Color */
  /*! base */
  --bg-base: #1e1e2e;
  /*! highlight-black */
  --bg-highlight-black: #212121;
  /*! white */
  --bg-white: #FFF;
  /*! white-muted */
  --bg-white-muted: rgba(255, 255, 255, 0.6);
  /*! bright-green-100 */
  --bg-bright-green-100: rgb(146, 191, 48);
  --bg-bright-green-100-light-20: #bddd78;
  --bg-bright-green-100-light-30: #d1e7a1;
  --bg-bright-green-100-dark-20: #546d1c;
  --bg-bright-green-100-dark-30: #354511;
  /*! bright-green-60 */
  --bg-bright-green-60: rgba(146, 191, 48, 0.6);
  --bg-bright-green-60-light-20: rgba(189, 221, 120, 0.6);
  --bg-bright-green-60-light-30: rgba(209, 231, 161, 0.6);
  --bg-bright-green-60-dark-20: rgba(84, 109, 28, 0.6);
  --bg-bright-green-60-dark-30: rgba(53, 69, 17, 0.6);
  /*! bright-green-40 */
  --bg-bright-green-40: rgba(146, 191, 48, 0.6);
  --bg-bright-green-40-light-20: rgba(189, 221, 120, 0.6);
  --bg-bright-green-40-light-30: rgba(209, 231, 161, 0.6);
  --bg-bright-green-40-dark-20: rgba(84, 109, 28, 0.6);
  --bg-bright-green-40-dark-30: rgba(53, 69, 17, 0.6);
  /*! Foreground Color */
  /*! base */
  --fg-base: #1e1e2e;
  /*! highlight-black */
  --fg-highlight-black: #212121;
  /*! white */
  --fg-white: #FFF;
  /*! white-muted */
  --fg-white-muted: rgba(255, 255, 255, 0.6);
  /*! bright-green-100 */
  --fg-bright-green-100: rgb(146, 191, 48);
  --fg-bright-green-100-light-20: #bddd78;
  --fg-bright-green-100-light-30: #d1e7a1;
  --fg-bright-green-100-dark-20: #546d1c;
  --fg-bright-green-100-dark-30: #354511;
  /*! bright-green-60 */
  --fg-bright-green-60: rgba(146, 191, 48, 0.6);
  --fg-bright-green-60-light-20: rgba(189, 221, 120, 0.6);
  --fg-bright-green-60-light-30: rgba(209, 231, 161, 0.6);
  --fg-bright-green-60-dark-20: rgba(84, 109, 28, 0.6);
  --fg-bright-green-60-dark-30: rgba(53, 69, 17, 0.6);
  /*! bright-green-40 */
  --fg-bright-green-40: rgba(146, 191, 48, 0.6);
  --fg-bright-green-40-light-20: rgba(189, 221, 120, 0.6);
  --fg-bright-green-40-light-30: rgba(209, 231, 161, 0.6);
  --fg-bright-green-40-dark-20: rgba(84, 109, 28, 0.6);
  --fg-bright-green-40-dark-30: rgba(53, 69, 17, 0.6);
  /** FONT **/
  --main-text-regular: "main-text-regular";
  --main-text-bold: "main-text-bold";
  --main-text-link: "main-text-link";
  --label-text: "label-text";
  --icon: "icon";
  --transition: all 0.4s ease;
}

body {
  margin: 0;
  padding: 1rem;
  background-color: var(--bg-base);
  font-family: "main-text-regular";
}

/** UTILITY: Colors **/
/*! Background Color */
/*! base */
.bg-base {
  background-color: var(--bg-base);
}

/*! highlight-black */
.bg-highlight-black {
  background-color: var(--bg-highlight-black);
}

/*! white */
.bg-white {
  background-color: var(--bg-white);
}

/*! white-muted */
.bg-white-muted {
  background-color: var(--bg-white-muted);
}

/*! bright-green-100 */
.bg-bright-green-100 {
  background-color: var(--bg-bright-green-100);
}

.bg-bright-green-100-light-20 {
  background-color: var(--bg-bright-green-100-light-20);
}

.bg-bright-green-100-light-30 {
  background-color: var(--bg-bright-green-100-light-30);
}

.bg-bright-green-100-dark-20 {
  background-color: var(--bg-bright-green-100-dark-20);
}

.bg-bright-green-100-dark-30 {
  background-color: var(--bg-bright-green-100-dark-30);
}

/*! bright-green-60 */
.bg-bright-green-60 {
  background-color: var(--bg-bright-green-60);
}

.bg-bright-green-60-light-20 {
  background-color: var(--bg-bright-green-60-light-20);
}

.bg-bright-green-60-light-30 {
  background-color: var(--bg-bright-green-60-light-30);
}

.bg-bright-green-60-dark-20 {
  background-color: var(--bg-bright-green-60-dark-20);
}

.bg-bright-green-60-dark-30 {
  background-color: var(--bg-bright-green-60-dark-30);
}

/*! bright-green-40 */
.bg-bright-green-40 {
  background-color: var(--bg-bright-green-40);
}

.bg-bright-green-40-light-20 {
  background-color: var(--bg-bright-green-40-light-20);
}

.bg-bright-green-40-light-30 {
  background-color: var(--bg-bright-green-40-light-30);
}

.bg-bright-green-40-dark-20 {
  background-color: var(--bg-bright-green-40-dark-20);
}

.bg-bright-green-40-dark-30 {
  background-color: var(--bg-bright-green-40-dark-30);
}

/*! Foreground Color */
/*! base */
.fg-base {
  color: var(--fg-base);
}

/*! highlight-black */
.fg-highlight-black {
  color: var(--fg-highlight-black);
}

/*! white */
.fg-white {
  color: var(--fg-white);
}

/*! white-muted */
.fg-white-muted {
  color: var(--fg-white-muted);
}

/*! bright-green-100 */
.fg-bright-green-100 {
  color: var(--fg-bright-green-100);
}

.fg-bright-green-100-light-20 {
  color: var(--fg-bright-green-100-light-20);
}

.fg-bright-green-100-light-30 {
  color: var(--fg-bright-green-100-light-30);
}

.fg-bright-green-100-dark-20 {
  color: var(--fg-bright-green-100-dark-20);
}

.fg-bright-green-100-dark-30 {
  color: var(--fg-bright-green-100-dark-30);
}

/*! bright-green-60 */
.fg-bright-green-60 {
  color: var(--fg-bright-green-60);
}

.fg-bright-green-60-light-20 {
  color: var(--fg-bright-green-60-light-20);
}

.fg-bright-green-60-light-30 {
  color: var(--fg-bright-green-60-light-30);
}

.fg-bright-green-60-dark-20 {
  color: var(--fg-bright-green-60-dark-20);
}

.fg-bright-green-60-dark-30 {
  color: var(--fg-bright-green-60-dark-30);
}

/*! bright-green-40 */
.fg-bright-green-40 {
  color: var(--fg-bright-green-40);
}

.fg-bright-green-40-light-20 {
  color: var(--fg-bright-green-40-light-20);
}

.fg-bright-green-40-light-30 {
  color: var(--fg-bright-green-40-light-30);
}

.fg-bright-green-40-dark-20 {
  color: var(--fg-bright-green-40-dark-20);
}

.fg-bright-green-40-dark-30 {
  color: var(--fg-bright-green-40-dark-30);
}

.main-text-regular-12 {
  font-family: "main-text-regular";
  font-size: 0.75rem;
}

.main-text-regular-14 {
  font-family: "main-text-regular";
  font-size: 0.875rem;
}

.main-text-regular-16 {
  font-family: "main-text-regular";
  font-size: 1rem;
}

.main-text-regular-18 {
  font-family: "main-text-regular";
  font-size: 1.125rem;
}

.main-text-regular-20 {
  font-family: "main-text-regular";
  font-size: 1.25rem;
}

.main-text-bold-12 {
  font-family: "main-text-bold";
  font-size: 0.75rem;
}

.main-text-bold-14 {
  font-family: "main-text-bold";
  font-size: 0.875rem;
}

.main-text-bold-16 {
  font-family: "main-text-bold";
  font-size: 1rem;
}

.main-text-bold-18 {
  font-family: "main-text-bold";
  font-size: 1.125rem;
}

.main-text-bold-20 {
  font-family: "main-text-bold";
  font-size: 1.25rem;
}

.main-text-link-12 {
  font-family: "main-text-link";
  font-size: 0.75rem;
}

.main-text-link-14 {
  font-family: "main-text-link";
  font-size: 0.875rem;
}

.main-text-link-16 {
  font-family: "main-text-link";
  font-size: 1rem;
}

.label-text-12 {
  font-family: "label-text";
  font-size: 0.75rem;
}

.label-text-14 {
  font-family: "label-text";
  font-size: 0.875rem;
}

.label-text-16 {
  font-family: "label-text";
  font-size: 1rem;
}

.label-text-18 {
  font-family: "label-text";
  font-size: 1.125rem;
}

.label-text-20 {
  font-family: "label-text";
  font-size: 1.25rem;
}

.label-text-22 {
  font-family: "label-text";
  font-size: 1.375rem;
}

.label-text-24 {
  font-family: "label-text";
  font-size: 1.5rem;
}

.label-text-26 {
  font-family: "label-text";
  font-size: 1.625rem;
}

.label-text-28 {
  font-family: "label-text";
  font-size: 1.75rem;
}

.icon-12 {
  font-family: "icon";
  font-size: 0.75rem;
}

.icon-14 {
  font-family: "icon";
  font-size: 0.875rem;
}

.icon-16 {
  font-family: "icon";
  font-size: 1rem;
}

.icon-18 {
  font-family: "icon";
  font-size: 1.125rem;
}

.icon-20 {
  font-family: "icon";
  font-size: 1.25rem;
}

.icon-22 {
  font-family: "icon";
  font-size: 1.375rem;
}

.icon-24 {
  font-family: "icon";
  font-size: 1.5rem;
}

.icon-26 {
  font-family: "icon";
  font-size: 1.625rem;
}

.icon-28 {
  font-family: "icon";
  font-size: 1.75rem;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.is-flex {
  display: flex;
}

.is-row {
  flex-direction: row;
}

.is-column {
  flex-direction: column;
}

.float-right {
  justify-content: flex-end;
}

.float-start {
  justify-content: flex-start;
}

.float-center {
  justify-content: center;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

a {
  color: rgba(0, 0, 0, 0.4);
  transition: all 0.4s ease;
  font-family: "main-text-bold";
}
a:hover {
  color: rgb(0, 0, 0);
}

p a {
  color: rgba(137, 126, 108, 0.6);
  transition: all 0.4s ease;
  font-family: "main-text-bold";
}

h1 {
  font-family: "heading";
  font-size: 2.625rem;
}

h2 {
  font-family: "heading";
  font-size: 2rem;
}

h3 {
  font-family: "heading";
  font-size: 1.75rem;
}

h4 {
  font-family: "heading";
  font-size: 1.625rem;
}

h5 {
  font-family: "heading";
  font-size: 1.5rem;
}

h6 {
  font-family: "heading";
  font-size: 1.375rem;
}

/* Uncomment when using atomic-bomb */
.Logo img {
  width: 200px;
}
.ShowStory .ShowStoryInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
  gap: 1rem;
}
.ShowStory .ShowStoryInfo .ShowStoryInfo__title {
  font-size: 2.5rem;
  font-weight: normal;
  margin: 0;
  text-align: left;
  padding: 0;
}
.ShowStory .ShowStoryInfo .ShowStoryInfo__date {
  font-size: 0.8rem;
  font-weight: normal;
  text-align: left;
  padding: 0;
  color: #FFF;
}
.ShowStory .ShowStoryInfo .ShowStoryInfo__title__icon {
  font-size: 3rem;
  font-weight: normal;
  font-family: "icon";
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 3px solid #FFF;
  color: #FFF;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.ShowStory .rotate_plus {
  transform: rotate(15deg);
}
.ShowStory .rotate_minus {
  transform: rotate(-15deg);
}
.ShowStory .ShowStory__images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.ShowStory .ShowStory__images__item {
  width: 400px;
  border-radius: 15px;
  overflow: hidden;
  margin: 2rem;
  border: 4px solid #FFF;
  display: inline-block;
  background-color: #FFF;
}
.ShowStory .ShowStory__images__item h5 {
  font-size: 1.2rem;
  font-weight: normal;
  text-align: center;
}
.ShowStory .ShowStory__images__item p {
  font-size: 1rem;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}
.ShowStory .ShowStory__images__item img {
  width: 100%;
  object-fit: cover;
}

